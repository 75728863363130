import { relations } from 'drizzle-orm'
import { boolean, foreignKey, integer, jsonb, pgEnum, pgTable, text, timestamp, uniqueIndex } from 'drizzle-orm/pg-core'

export const UserRole = pgEnum('UserRole', ['USER', 'ADMIN'])

export const Element = pgEnum('Element', ['NO_ELEMENT', 'LIGHT', 'DARK', 'WATER', 'FIRE', 'EARTH', 'WIND'])

export const MonsterType = pgEnum('MonsterType', ['COMMON_MOBS', 'COMMON_MINI_BOSS', 'COMMON_BOSS', 'EVENT_MOBS', 'EVENT_MINI_BOSS', 'EVENT_BOSS'])

export const ModifiersName = pgEnum('ModifiersName', ['STR', 'INT', 'VIT', 'AGI', 'DEX', 'MAX_MP', 'AGGRO', 'WEAPON_RANGE', 'HP_REGEN', 'MP_REGEN', 'PHYSICAL_ATK', 'MAGICAL_ATK', 'WEAPON_ATK', 'UNSHEATHE_ATK', 'PHYSICAL_PIERCE', 'MAGICAL_PIERCE', 'CRITICAL_RATE', 'CRITICAL_DAMAGE', 'MAGIC_CRT_CONVERSION_RATE', 'MAGIC_CRT_DAMAGE_CONVERSION_RATE', 'SHORT_RANGE_DAMAGE', 'LONG_RANGE_DAMAGE', 'STRONGER_AGAINST_NETURAL', 'STRONGER_AGAINST_LIGHT', 'STRONGER_AGAINST_DARK', 'STRONGER_AGAINST_WATER', 'STRONGER_AGAINST_FIRE', 'STRONGER_AGAINST_EARTH', 'STRONGER_AGAINST_WIND', 'STABILITY', 'ACCURACY', 'ADDITIONAL_PHYSICS', 'ADDITIONAL_MAGIC', 'ANTICIPATE', 'GUARD_BREAK', 'REFLECT', 'ABSOLUTA_ACCURACY', 'ATK_UP_STR', 'ATK_UP_INT', 'ATK_UP_VIT', 'ATK_UP_AGI', 'ATK_UP_DEX', 'MATK_UP_STR', 'MATK_UP_INT', 'MATK_UP_VIT', 'MATK_UP_AGI', 'MATK_UP_DEX', 'ATK_DOWN_STR', 'ATK_DOWN_INT', 'ATK_DOWN_VIT', 'ATK_DOWN_AGI', 'ATK_DOWN_DEX', 'MATK_DOWN_STR', 'MATK_DOWN_INT', 'MATK_DOWN_VIT', 'MATK_DOWN_AGI', 'MATK_DOWN_DEX', 'MAX_HP', 'PHYSICAL_DEF', 'MAGICAL_DEF', 'PHYSICAL_RESISTANCE', 'MAGICAL_RESISTANCE', 'NEUTRAL_RESISTANCE', 'LIGHT_RESISTANCE', 'DARK_RESISTANCE', 'WATER_RESISTANCE', 'FIRE_RESISTANCE', 'EARTH_RESISTANCE', 'WIND_RESISTANCE', 'DODGE', 'AILMENT_RESISTANCE', 'BASE_GUARD_POWER', 'GUARD_POWER', 'BASE_GUARD_RECHARGE', 'GUARD_RECHANGE', 'EVASION_RECHARGE', 'PHYSICAL_BARRIER', 'MAGICAL_BARRIER', 'FRACTIONAL_BARRIER', 'BARRIER_COOLDOWN', 'REDUCE_DMG_FLOOR', 'REDUCE_DMG_METEOR', 'REDUCE_DMG_PLAYER_EPICENTER', 'REDUCE_DMG_FOE_EPICENTER', 'REDUCE_DMG_BOWLING', 'REDUCE_DMG_BULLET', 'REDUCE_DMG_STRAIGHT_LINE', 'REDUCE_DMG_CHARGE', 'ABSOLUTE_DODGE', 'ASPD', 'CSPD', 'MSPD', 'DROP_RATE', 'REVIVE_TIME', 'FLINCH_UNAVAILABLE', 'TUMBLE_UNAVAILABLE', 'STUN_UNAVAILABLE', 'INVINCIBLE_AID', 'EXP_RATE', 'PET_EXP', 'ITEM_COOLDOWN', 'RECOIL_DAMAGE', 'GEM_POWDER_DROP'])

export const SpecialAbiType = pgEnum('SpecialAbiType', ['NULL', 'LUK', 'CRI', 'TEC', 'MEN'])

export const CrystalType = pgEnum('CrystalType', ['GENERAL', 'WEAPONCRYSTAL', 'BODYCRYSTAL', 'ADDITIONALCRYSTAL', 'SPECIALCRYSTAL'])

export const MainWeaponType = pgEnum('MainWeaponType', ['NO_WEAPON', 'ONE_HAND_SWORD', 'TWO_HANDS_SWORD', 'BOW', 'BOWGUN', 'STAFF', 'MAGIC_DEVICE', 'KNUCKLE', 'HALBERD', 'KATANA'])

export const SubWeaponType = pgEnum('SubWeaponType', ['NO_WEAPON', 'ONE_HAND_SWORD', 'MAGIC_DEVICE', 'KNUCKLE', 'KATANA', 'ARROW', 'DAGGER', 'NINJUTSUSCROLL', 'SHIELD'])

export const BodyArmorType = pgEnum('BodyArmorType', ['NORMAL', 'LIGHT', 'HEAVY'])

export const SkillTreeName = pgEnum('SkillTreeName', ['BLADE', 'SHOT', 'MAGIC', 'MARTIAL', 'DUALSWORD', 'HALBERD', 'MONONOFU', 'CRUSHER', 'SPRITE'])

export const SkillType = pgEnum('SkillType', ['ACTIVE_SKILL', 'PASSIVE_SKILL'])

export const SkillExtraActionType = pgEnum('SkillExtraActionType', ['None', 'Chanting', 'Charging'])

export const YieldType = pgEnum('YieldType', ['PersistentEffect', 'ImmediateEffect'])

export const DurationType = pgEnum('DurationType', ['FRAME', 'SKILL', 'UNLIMITED'])

export const ComboType = pgEnum('ComboType', ['NULL'])

export const CharacterType = pgEnum('CharacterType', ['Tank', 'Mage', 'Ranger', 'Marksman'])

export const user = pgTable('user', {
	id: text('id').notNull().primaryKey(),
	name: text('name'),
	email: text('email').unique(),
	emailVerified: timestamp('emailVerified', { precision: 3 }),
	image: text('image'),
	userRole: UserRole('userRole').notNull()
});

export const account = pgTable('account', {
	id: text('id').notNull().primaryKey(),
	type: text('type').notNull(),
	provider: text('provider').notNull(),
	providerAccountId: text('providerAccountId').notNull(),
	refresh_token: text('refresh_token'),
	access_token: text('access_token'),
	expires_at: integer('expires_at'),
	token_type: text('token_type'),
	scope: text('scope'),
	id_token: text('id_token'),
	session_state: text('session_state'),
	userId: text('userId').notNull()
}, (account) => ({
	'account_user_fkey': foreignKey({
		name: 'account_user_fkey',
		columns: [account.userId],
		foreignColumns: [user.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'account_provider_providerAccountId_unique_idx': uniqueIndex('account_provider_providerAccountId_key')
		.on(account.provider, account.providerAccountId)
}));

export const session = pgTable('session', {
	id: text('id').notNull().primaryKey(),
	sessionToken: text('sessionToken').notNull().unique(),
	expires: timestamp('expires', { precision: 3 }).notNull(),
	userId: text('userId').notNull()
}, (session) => ({
	'session_user_fkey': foreignKey({
		name: 'session_user_fkey',
		columns: [session.userId],
		foreignColumns: [user.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const verification_token = pgTable('verification_token', {
	identifier: text('identifier').notNull(),
	token: text('token').notNull().unique(),
	expires: timestamp('expires', { precision: 3 }).notNull()
}, (verification_token) => ({
	'verification_token_identifier_token_unique_idx': uniqueIndex('verification_token_identifier_token_key')
		.on(verification_token.identifier, verification_token.token)
}));

export const post = pgTable('post', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdById: text('createdById').notNull()
}, (post) => ({
	'post_createdBy_fkey': foreignKey({
		name: 'post_createdBy_fkey',
		columns: [post.createdById],
		foreignColumns: [user.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const user_create_data = pgTable('user_create_data', {
	userId: text('userId').notNull().primaryKey()
}, (user_create_data) => ({
	'user_create_data_belongToUser_fkey': foreignKey({
		name: 'user_create_data_belongToUser_fkey',
		columns: [user_create_data.userId],
		foreignColumns: [user.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const user_update_data = pgTable('user_update_data', {
	userId: text('userId').notNull().primaryKey()
}, (user_update_data) => ({
	'user_update_data_belongToUser_fkey': foreignKey({
		name: 'user_update_data_belongToUser_fkey',
		columns: [user_update_data.userId],
		foreignColumns: [user.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const monster = pgTable('monster', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	monsterType: MonsterType('monsterType').notNull(),
	baseLv: integer('baseLv').notNull(),
	experience: integer('experience').notNull(),
	address: text('address').notNull(),
	element: Element('element').notNull(),
	radius: integer('radius').notNull(),
	maxhp: integer('maxhp').notNull(),
	physicalDefense: integer('physicalDefense').notNull(),
	physicalResistance: integer('physicalResistance').notNull(),
	magicalDefense: integer('magicalDefense').notNull(),
	magicalResistance: integer('magicalResistance').notNull(),
	criticalResistance: integer('criticalResistance').notNull(),
	avoidance: integer('avoidance').notNull(),
	dodge: integer('dodge').notNull(),
	block: integer('block').notNull(),
	normalAttackResistanceModifier: integer('normalAttackResistanceModifier').notNull(),
	physicalAttackResistanceModifier: integer('physicalAttackResistanceModifier').notNull(),
	magicalAttackResistanceModifier: integer('magicalAttackResistanceModifier').notNull(),
	difficultyOfTank: integer('difficultyOfTank').notNull(),
	difficultyOfMelee: integer('difficultyOfMelee').notNull(),
	difficultyOfRanged: integer('difficultyOfRanged').notNull(),
	possibilityOfRunningAround: integer('possibilityOfRunningAround').notNull(),
	extraDetails: text('extraDetails').notNull(),
	dataSources: text('dataSources').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique(),
	imageId: text('imageId').notNull()
}, (monster) => ({
	'monster_updatedBy_fkey': foreignKey({
		name: 'monster_updatedBy_fkey',
		columns: [monster.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'monster_createdBy_fkey': foreignKey({
		name: 'monster_createdBy_fkey',
		columns: [monster.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'monster_statistics_fkey': foreignKey({
		name: 'monster_statistics_fkey',
		columns: [monster.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'monster_image_fkey': foreignKey({
		name: 'monster_image_fkey',
		columns: [monster.imageId],
		foreignColumns: [image.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const modifier = pgTable('modifier', {
	id: text('id').notNull().primaryKey(),
	formula: text('formula').notNull(),
	belongToModifiersListId: text('belongToModifiersListId').notNull()
}, (modifier) => ({
	'modifier_belongToModifiersList_fkey': foreignKey({
		name: 'modifier_belongToModifiersList_fkey',
		columns: [modifier.belongToModifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const modifiers_list = pgTable('modifiers_list', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull()
});

export const crystal = pgTable('crystal', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	crystalType: CrystalType('crystalType').notNull(),
	front: integer('front').notNull(),
	modifiersListId: text('modifiersListId').notNull(),
	extraDetails: text('extraDetails'),
	dataSources: text('dataSources'),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (crystal) => ({
	'crystal_modifiersList_fkey': foreignKey({
		name: 'crystal_modifiersList_fkey',
		columns: [crystal.modifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'crystal_updatedBy_fkey': foreignKey({
		name: 'crystal_updatedBy_fkey',
		columns: [crystal.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'crystal_createdBy_fkey': foreignKey({
		name: 'crystal_createdBy_fkey',
		columns: [crystal.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'crystal_statistics_fkey': foreignKey({
		name: 'crystal_statistics_fkey',
		columns: [crystal.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const main_weapon = pgTable('main_weapon', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	mainWeaponType: MainWeaponType('mainWeaponType').notNull(),
	baseAtk: integer('baseAtk').notNull(),
	refinement: integer('refinement').notNull(),
	stability: integer('stability').notNull(),
	element: Element('element').notNull(),
	modifiersListId: text('modifiersListId').notNull(),
	extraDetails: text('extraDetails').notNull(),
	dataSources: text('dataSources').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (main_weapon) => ({
	'main_weapon_modifiersList_fkey': foreignKey({
		name: 'main_weapon_modifiersList_fkey',
		columns: [main_weapon.modifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'main_weapon_updatedBy_fkey': foreignKey({
		name: 'main_weapon_updatedBy_fkey',
		columns: [main_weapon.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'main_weapon_createdBy_fkey': foreignKey({
		name: 'main_weapon_createdBy_fkey',
		columns: [main_weapon.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'main_weapon_statistics_fkey': foreignKey({
		name: 'main_weapon_statistics_fkey',
		columns: [main_weapon.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const sub_weapon = pgTable('sub_weapon', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	subWeaponType: SubWeaponType('subWeaponType').notNull(),
	baseAtk: integer('baseAtk').notNull(),
	refinement: integer('refinement').notNull(),
	stability: integer('stability').notNull(),
	element: Element('element').notNull(),
	modifiersListId: text('modifiersListId').notNull(),
	extraDetails: text('extraDetails').notNull(),
	dataSources: text('dataSources').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (sub_weapon) => ({
	'sub_weapon_modifiersList_fkey': foreignKey({
		name: 'sub_weapon_modifiersList_fkey',
		columns: [sub_weapon.modifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'sub_weapon_updatedBy_fkey': foreignKey({
		name: 'sub_weapon_updatedBy_fkey',
		columns: [sub_weapon.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'sub_weapon_createdBy_fkey': foreignKey({
		name: 'sub_weapon_createdBy_fkey',
		columns: [sub_weapon.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'sub_weapon_statistics_fkey': foreignKey({
		name: 'sub_weapon_statistics_fkey',
		columns: [sub_weapon.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const body_armor = pgTable('body_armor', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	bodyArmorType: BodyArmorType('bodyArmorType').notNull(),
	refinement: integer('refinement').notNull(),
	baseDef: integer('baseDef').notNull(),
	modifiersListId: text('modifiersListId').notNull(),
	extraDetails: text('extraDetails').notNull(),
	dataSources: text('dataSources').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (body_armor) => ({
	'body_armor_modifiersList_fkey': foreignKey({
		name: 'body_armor_modifiersList_fkey',
		columns: [body_armor.modifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'body_armor_updatedBy_fkey': foreignKey({
		name: 'body_armor_updatedBy_fkey',
		columns: [body_armor.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'body_armor_createdBy_fkey': foreignKey({
		name: 'body_armor_createdBy_fkey',
		columns: [body_armor.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'body_armor_statistics_fkey': foreignKey({
		name: 'body_armor_statistics_fkey',
		columns: [body_armor.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const additional_equipment = pgTable('additional_equipment', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	refinement: integer('refinement').notNull(),
	modifiersListId: text('modifiersListId').notNull(),
	extraDetails: text('extraDetails').notNull(),
	dataSources: text('dataSources').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (additional_equipment) => ({
	'additional_equipment_modifiersList_fkey': foreignKey({
		name: 'additional_equipment_modifiersList_fkey',
		columns: [additional_equipment.modifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'additional_equipment_updatedBy_fkey': foreignKey({
		name: 'additional_equipment_updatedBy_fkey',
		columns: [additional_equipment.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'additional_equipment_createdBy_fkey': foreignKey({
		name: 'additional_equipment_createdBy_fkey',
		columns: [additional_equipment.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'additional_equipment_statistics_fkey': foreignKey({
		name: 'additional_equipment_statistics_fkey',
		columns: [additional_equipment.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const special_equipment = pgTable('special_equipment', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	modifiersListId: text('modifiersListId').notNull(),
	extraDetails: text('extraDetails').notNull(),
	dataSources: text('dataSources').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (special_equipment) => ({
	'special_equipment_modifiersList_fkey': foreignKey({
		name: 'special_equipment_modifiersList_fkey',
		columns: [special_equipment.modifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'special_equipment_updatedBy_fkey': foreignKey({
		name: 'special_equipment_updatedBy_fkey',
		columns: [special_equipment.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'special_equipment_createdBy_fkey': foreignKey({
		name: 'special_equipment_createdBy_fkey',
		columns: [special_equipment.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'special_equipment_statistics_fkey': foreignKey({
		name: 'special_equipment_statistics_fkey',
		columns: [special_equipment.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const skill = pgTable('skill', {
	id: text('id').notNull().primaryKey(),
	skillTreeName: SkillTreeName('skillTreeName').notNull(),
	name: text('name').notNull(),
	skillType: SkillType('skillType').notNull(),
	weaponElementDependencyType: boolean('weaponElementDependencyType').notNull(),
	element: Element('element').notNull(),
	skillDescription: text('skillDescription'),
	extraDetails: text('extraDetails').notNull(),
	dataSources: text('dataSources').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (skill) => ({
	'skill_updatedBy_fkey': foreignKey({
		name: 'skill_updatedBy_fkey',
		columns: [skill.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'skill_createdBy_fkey': foreignKey({
		name: 'skill_createdBy_fkey',
		columns: [skill.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'skill_statistics_fkey': foreignKey({
		name: 'skill_statistics_fkey',
		columns: [skill.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const skill_effect = pgTable('skill_effect', {
	id: text('id').notNull().primaryKey(),
	condition: text('condition').notNull(),
	description: text('description').notNull(),
	actionBaseDurationFormula: text('actionBaseDurationFormula').notNull(),
	actionModifiableDurationFormula: text('actionModifiableDurationFormula').notNull(),
	skillExtraActionType: SkillExtraActionType('skillExtraActionType').notNull(),
	chantingBaseDurationFormula: text('chantingBaseDurationFormula').notNull(),
	chantingModifiableDurationFormula: text('chantingModifiableDurationFormula').notNull(),
	chargingBaseDurationFormula: text('chargingBaseDurationFormula').notNull(),
	chargingModifiableDurationFormula: text('chargingModifiableDurationFormula').notNull(),
	skillStartupFramesFormula: text('skillStartupFramesFormula').notNull(),
	belongToskillId: text('belongToskillId').notNull()
}, (skill_effect) => ({
	'skill_effect_belongToSkill_fkey': foreignKey({
		name: 'skill_effect_belongToSkill_fkey',
		columns: [skill_effect.belongToskillId],
		foreignColumns: [skill.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const skill_cost = pgTable('skill_cost', {
	id: text('id').notNull().primaryKey(),
	name: text('name'),
	costFormula: text('costFormula').notNull(),
	skillEffectId: text('skillEffectId')
}, (skill_cost) => ({
	'skill_cost_belongToSkillEffect_fkey': foreignKey({
		name: 'skill_cost_belongToSkillEffect_fkey',
		columns: [skill_cost.skillEffectId],
		foreignColumns: [skill_effect.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const skill_yield = pgTable('skill_yield', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	yieldType: YieldType('yieldType').notNull(),
	yieldFormula: text('yieldFormula').notNull(),
	mutationTimingFormula: text('mutationTimingFormula'),
	skillEffectId: text('skillEffectId')
}, (skill_yield) => ({
	'skill_yield_belongToSkillEffect_fkey': foreignKey({
		name: 'skill_yield_belongToSkillEffect_fkey',
		columns: [skill_yield.skillEffectId],
		foreignColumns: [skill_effect.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const pet = pgTable('pet', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	extraDetails: text('extraDetails').notNull(),
	dataSources: text('dataSources').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (pet) => ({
	'pet_updatedBy_fkey': foreignKey({
		name: 'pet_updatedBy_fkey',
		columns: [pet.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'pet_createdBy_fkey': foreignKey({
		name: 'pet_createdBy_fkey',
		columns: [pet.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'pet_statistics_fkey': foreignKey({
		name: 'pet_statistics_fkey',
		columns: [pet.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const consumable = pgTable('consumable', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	modifiersListId: text('modifiersListId').notNull(),
	extraDetails: text('extraDetails').notNull(),
	dataSources: text('dataSources').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (consumable) => ({
	'consumable_modifiersList_fkey': foreignKey({
		name: 'consumable_modifiersList_fkey',
		columns: [consumable.modifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'consumable_updatedBy_fkey': foreignKey({
		name: 'consumable_updatedBy_fkey',
		columns: [consumable.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'consumable_createdBy_fkey': foreignKey({
		name: 'consumable_createdBy_fkey',
		columns: [consumable.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'consumable_statistics_fkey': foreignKey({
		name: 'consumable_statistics_fkey',
		columns: [consumable.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const combo = pgTable('combo', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	userCreateUserId: text('userCreateUserId')
}, (combo) => ({
	'combo_user_create_data_fkey': foreignKey({
		name: 'combo_user_create_data_fkey',
		columns: [combo.userCreateUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const combo_step = pgTable('combo_step', {
	id: text('id').notNull().primaryKey(),
	order: integer('order').notNull(),
	comboType: ComboType('comboType').notNull(),
	skillId: text('skillId').notNull(),
	comboId: text('comboId').notNull()
}, (combo_step) => ({
	'combo_step_skill_fkey': foreignKey({
		name: 'combo_step_skill_fkey',
		columns: [combo_step.skillId],
		foreignColumns: [skill.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'combo_step_belongToCombo_fkey': foreignKey({
		name: 'combo_step_belongToCombo_fkey',
		columns: [combo_step.comboId],
		foreignColumns: [combo.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const character = pgTable('character', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	characterType: CharacterType('characterType').notNull(),
	lv: integer('lv').notNull(),
	baseStr: integer('baseStr').notNull(),
	baseInt: integer('baseInt').notNull(),
	baseVit: integer('baseVit').notNull(),
	baseAgi: integer('baseAgi').notNull(),
	baseDex: integer('baseDex').notNull(),
	specialAbiType: SpecialAbiType('specialAbiType').notNull(),
	specialAbiValue: integer('specialAbiValue').notNull(),
	mainWeaponId: text('mainWeaponId').notNull(),
	subWeaponId: text('subWeaponId').notNull(),
	bodyArmorId: text('bodyArmorId').notNull(),
	additionalEquipmentId: text('additionalEquipmentId').notNull(),
	specialEquipmentId: text('specialEquipmentId').notNull(),
	fashionModifiersListId: text('fashionModifiersListId').notNull().unique(),
	CuisineModifiersListId: text('CuisineModifiersListId').notNull().unique(),
	petId: text('petId').notNull(),
	modifiersListId: text('modifiersListId').notNull().unique(),
	extraDetails: text('extraDetails').notNull(),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique(),
	imageId: text('imageId').notNull().unique()
}, (character) => ({
	'character_mainWeapon_fkey': foreignKey({
		name: 'character_mainWeapon_fkey',
		columns: [character.mainWeaponId],
		foreignColumns: [main_weapon.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_subWeapon_fkey': foreignKey({
		name: 'character_subWeapon_fkey',
		columns: [character.subWeaponId],
		foreignColumns: [sub_weapon.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_bodyArmor_fkey': foreignKey({
		name: 'character_bodyArmor_fkey',
		columns: [character.bodyArmorId],
		foreignColumns: [body_armor.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_additionalEquipment_fkey': foreignKey({
		name: 'character_additionalEquipment_fkey',
		columns: [character.additionalEquipmentId],
		foreignColumns: [additional_equipment.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_specialEquipment_fkey': foreignKey({
		name: 'character_specialEquipment_fkey',
		columns: [character.specialEquipmentId],
		foreignColumns: [special_equipment.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_fashion_fkey': foreignKey({
		name: 'character_fashion_fkey',
		columns: [character.fashionModifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_cuisine_fkey': foreignKey({
		name: 'character_cuisine_fkey',
		columns: [character.CuisineModifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_pet_fkey': foreignKey({
		name: 'character_pet_fkey',
		columns: [character.petId],
		foreignColumns: [pet.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_modifiersList_fkey': foreignKey({
		name: 'character_modifiersList_fkey',
		columns: [character.modifiersListId],
		foreignColumns: [modifiers_list.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_updatedBy_fkey': foreignKey({
		name: 'character_updatedBy_fkey',
		columns: [character.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_createdBy_fkey': foreignKey({
		name: 'character_createdBy_fkey',
		columns: [character.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_statistics_fkey': foreignKey({
		name: 'character_statistics_fkey',
		columns: [character.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'character_image_fkey': foreignKey({
		name: 'character_image_fkey',
		columns: [character.imageId],
		foreignColumns: [image.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const memeber = pgTable('memeber', {
	id: text('id').notNull().primaryKey(),
	characterId: text('characterId').notNull(),
	flow: jsonb('flow').notNull()
}, (memeber) => ({
	'memeber_character_fkey': foreignKey({
		name: 'memeber_character_fkey',
		columns: [memeber.characterId],
		foreignColumns: [character.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const mob = pgTable('mob', {
	id: text('id').notNull().primaryKey(),
	monsterId: text('monsterId').notNull(),
	star: integer('star').notNull(),
	flow: text('flow').notNull()
}, (mob) => ({
	'mob_monster_fkey': foreignKey({
		name: 'mob_monster_fkey',
		columns: [mob.monsterId],
		foreignColumns: [monster.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const analyzer = pgTable('analyzer', {
	id: text('id').notNull().primaryKey(),
	name: text('name').notNull(),
	extraDetails: text('extraDetails'),
	updatedAt: timestamp('updatedAt', { precision: 3 }).notNull(),
	createdAt: timestamp('createdAt', { precision: 3 }).notNull(),
	updatedByUserId: text('updatedByUserId'),
	createdByUserId: text('createdByUserId'),
	statisticsId: text('statisticsId').notNull().unique()
}, (analyzer) => ({
	'analyzer_updatedBy_fkey': foreignKey({
		name: 'analyzer_updatedBy_fkey',
		columns: [analyzer.updatedByUserId],
		foreignColumns: [user_update_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'analyzer_createdBy_fkey': foreignKey({
		name: 'analyzer_createdBy_fkey',
		columns: [analyzer.createdByUserId],
		foreignColumns: [user_create_data.userId]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'analyzer_statistics_fkey': foreignKey({
		name: 'analyzer_statistics_fkey',
		columns: [analyzer.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const process = pgTable('process', {
	id: text('id').notNull().primaryKey()
});

export const step = pgTable('step', {
	id: text('id').notNull().primaryKey(),
	order: integer('order').notNull(),
	skillId: text('skillId').notNull(),
	processId: text('processId').notNull()
}, (step) => ({
	'step_skill_fkey': foreignKey({
		name: 'step_skill_fkey',
		columns: [step.skillId],
		foreignColumns: [skill.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'step_belongToProcess_fkey': foreignKey({
		name: 'step_belongToProcess_fkey',
		columns: [step.processId],
		foreignColumns: [process.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const statistics = pgTable('statistics', {
	id: text('id').notNull().primaryKey(),
	monsterId: text('monsterId'),
	crystalId: text('crystalId'),
	mainWeaponId: text('mainWeaponId'),
	subWeaponId: text('subWeaponId'),
	bodyArmorId: text('bodyArmorId'),
	additionalEquipmentId: text('additionalEquipmentId'),
	specialEquipmentId: text('specialEquipmentId'),
	skillId: text('skillId'),
	petId: text('petId'),
	consumableId: text('consumableId'),
	characterId: text('characterId'),
	analyzerId: text('analyzerId')
});

export const image = pgTable('image', {
	id: text('id').notNull().primaryKey(),
	dataUrl: text('dataUrl').notNull(),
	main_weaponId: text('main_weaponId'),
	sub_weaponId: text('sub_weaponId'),
	body_armorId: text('body_armorId'),
	additional_equipmentId: text('additional_equipmentId'),
	special_equipmentId: text('special_equipmentId')
}, (image) => ({
	'image_belongToMainWeapon_fkey': foreignKey({
		name: 'image_belongToMainWeapon_fkey',
		columns: [image.main_weaponId],
		foreignColumns: [main_weapon.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'image_belongToSubWeapon_fkey': foreignKey({
		name: 'image_belongToSubWeapon_fkey',
		columns: [image.sub_weaponId],
		foreignColumns: [sub_weapon.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'image_belongToBodyArmor_fkey': foreignKey({
		name: 'image_belongToBodyArmor_fkey',
		columns: [image.body_armorId],
		foreignColumns: [body_armor.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'image_belongToAdditionalEquipment_fkey': foreignKey({
		name: 'image_belongToAdditionalEquipment_fkey',
		columns: [image.additional_equipmentId],
		foreignColumns: [additional_equipment.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'image_belongToSpecialEquipment_fkey': foreignKey({
		name: 'image_belongToSpecialEquipment_fkey',
		columns: [image.special_equipmentId],
		foreignColumns: [special_equipment.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const rate = pgTable('rate', {
	id: text('id').notNull().primaryKey(),
	rate: integer('rate').notNull(),
	userId: text('userId').notNull(),
	statisticsId: text('statisticsId').notNull()
}, (rate) => ({
	'rate_belongToUser_fkey': foreignKey({
		name: 'rate_belongToUser_fkey',
		columns: [rate.userId],
		foreignColumns: [user.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'rate_belongToStatistics_fkey': foreignKey({
		name: 'rate_belongToStatistics_fkey',
		columns: [rate.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const usage_timestamp = pgTable('usage_timestamp', {
	timestamp: timestamp('timestamp', { precision: 3 }).notNull().primaryKey(),
	statisticsId: text('statisticsId')
}, (usage_timestamp) => ({
	'usage_timestamp_usedByStatistics_fkey': foreignKey({
		name: 'usage_timestamp_usedByStatistics_fkey',
		columns: [usage_timestamp.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const view_timestamp = pgTable('view_timestamp', {
	timestamp: timestamp('timestamp', { precision: 3 }).notNull().primaryKey(),
	statisticsId: text('statisticsId')
}, (view_timestamp) => ({
	'view_timestamp_usedByStatistics_fkey': foreignKey({
		name: 'view_timestamp_usedByStatistics_fkey',
		columns: [view_timestamp.statisticsId],
		foreignColumns: [statistics.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const crystalTomain_weapon = pgTable('_crystalTomain_weapon', {
	main_weaponId: text('A').notNull(),
	crystalId: text('B').notNull()
}, (crystalTomain_weapon) => ({
	'_crystalTomain_weapon_main_weapon_fkey': foreignKey({
		name: '_crystalTomain_weapon_main_weapon_fkey',
		columns: [crystalTomain_weapon.main_weaponId],
		foreignColumns: [main_weapon.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'_crystalTomain_weapon_crystal_fkey': foreignKey({
		name: '_crystalTomain_weapon_crystal_fkey',
		columns: [crystalTomain_weapon.crystalId],
		foreignColumns: [crystal.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const body_armorTocrystal = pgTable('_body_armorTocrystal', {
	crystalId: text('A').notNull(),
	body_armorId: text('B').notNull()
}, (body_armorTocrystal) => ({
	'_body_armorTocrystal_crystal_fkey': foreignKey({
		name: '_body_armorTocrystal_crystal_fkey',
		columns: [body_armorTocrystal.crystalId],
		foreignColumns: [crystal.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'_body_armorTocrystal_body_armor_fkey': foreignKey({
		name: '_body_armorTocrystal_body_armor_fkey',
		columns: [body_armorTocrystal.body_armorId],
		foreignColumns: [body_armor.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const additional_equipmentTocrystal = pgTable('_additional_equipmentTocrystal', {
	crystalId: text('A').notNull(),
	additional_equipmentId: text('B').notNull()
}, (additional_equipmentTocrystal) => ({
	'_additional_equipmentTocrystal_crystal_fkey': foreignKey({
		name: '_additional_equipmentTocrystal_crystal_fkey',
		columns: [additional_equipmentTocrystal.crystalId],
		foreignColumns: [crystal.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'_additional_equipmentTocrystal_additional_equipment_fkey': foreignKey({
		name: '_additional_equipmentTocrystal_additional_equipment_fkey',
		columns: [additional_equipmentTocrystal.additional_equipmentId],
		foreignColumns: [additional_equipment.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const crystalTospecial_equipment = pgTable('_crystalTospecial_equipment', {
	special_equipmentId: text('A').notNull(),
	crystalId: text('B').notNull()
}, (crystalTospecial_equipment) => ({
	'_crystalTospecial_equipment_special_equipment_fkey': foreignKey({
		name: '_crystalTospecial_equipment_special_equipment_fkey',
		columns: [crystalTospecial_equipment.special_equipmentId],
		foreignColumns: [special_equipment.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade'),
	'_crystalTospecial_equipment_crystal_fkey': foreignKey({
		name: '_crystalTospecial_equipment_crystal_fkey',
		columns: [crystalTospecial_equipment.crystalId],
		foreignColumns: [crystal.id]
	})
		.onDelete('cascade')
		.onUpdate('cascade')
}));

export const userRelations = relations(user, ({ many }) => ({
	accounts: many(account, {
		relationName: 'accountTouser'
	}),
	sessions: many(session, {
		relationName: 'sessionTouser'
	}),
	posts: many(post, {
		relationName: 'postTouser'
	}),
	rates: many(rate, {
		relationName: 'rateTouser'
	}),
	create: many(user_create_data, {
		relationName: 'userTouser_create_data'
	}),
	update: many(user_update_data, {
		relationName: 'userTouser_update_data'
	})
}));

export const accountRelations = relations(account, ({ one }) => ({
	user: one(user, {
		relationName: 'accountTouser',
		fields: [account.userId],
		references: [user.id]
	})
}));

export const sessionRelations = relations(session, ({ one }) => ({
	user: one(user, {
		relationName: 'sessionTouser',
		fields: [session.userId],
		references: [user.id]
	})
}));

export const postRelations = relations(post, ({ one }) => ({
	createdBy: one(user, {
		relationName: 'postTouser',
		fields: [post.createdById],
		references: [user.id]
	})
}));

export const user_create_dataRelations = relations(user_create_data, ({ many, one }) => ({
	characters: many(character, {
		relationName: 'characterTouser_create_data'
	}),
	monsters: many(monster, {
		relationName: 'monsterTouser_create_data'
	}),
	crystals: many(crystal, {
		relationName: 'crystalTouser_create_data'
	}),
	mainWeapons: many(main_weapon, {
		relationName: 'main_weaponTouser_create_data'
	}),
	subWeapons: many(sub_weapon, {
		relationName: 'sub_weaponTouser_create_data'
	}),
	bodyArmors: many(body_armor, {
		relationName: 'body_armorTouser_create_data'
	}),
	additionalEquipments: many(additional_equipment, {
		relationName: 'additional_equipmentTouser_create_data'
	}),
	specialEquipments: many(special_equipment, {
		relationName: 'special_equipmentTouser_create_data'
	}),
	pets: many(pet, {
		relationName: 'petTouser_create_data'
	}),
	skills: many(skill, {
		relationName: 'skillTouser_create_data'
	}),
	consumables: many(consumable, {
		relationName: 'consumableTouser_create_data'
	}),
	combos: many(combo, {
		relationName: 'comboTouser_create_data'
	}),
	analyzers: many(analyzer, {
		relationName: 'analyzerTouser_create_data'
	}),
	belongToUser: one(user, {
		relationName: 'userTouser_create_data',
		fields: [user_create_data.userId],
		references: [user.id]
	})
}));

export const user_update_dataRelations = relations(user_update_data, ({ many, one }) => ({
	monsters: many(monster, {
		relationName: 'monsterTouser_update_data'
	}),
	crystals: many(crystal, {
		relationName: 'crystalTouser_update_data'
	}),
	mainWeapons: many(main_weapon, {
		relationName: 'main_weaponTouser_update_data'
	}),
	subWeapons: many(sub_weapon, {
		relationName: 'sub_weaponTouser_update_data'
	}),
	bodyArmors: many(body_armor, {
		relationName: 'body_armorTouser_update_data'
	}),
	additionalEquipments: many(additional_equipment, {
		relationName: 'additional_equipmentTouser_update_data'
	}),
	specialEquipments: many(special_equipment, {
		relationName: 'special_equipmentTouser_update_data'
	}),
	pets: many(pet, {
		relationName: 'petTouser_update_data'
	}),
	skills: many(skill, {
		relationName: 'skillTouser_update_data'
	}),
	consumables: many(consumable, {
		relationName: 'consumableTouser_update_data'
	}),
	belongToUser: one(user, {
		relationName: 'userTouser_update_data',
		fields: [user_update_data.userId],
		references: [user.id]
	}),
	Character: many(character, {
		relationName: 'characterTouser_update_data'
	}),
	Analyzer: many(analyzer, {
		relationName: 'analyzerTouser_update_data'
	})
}));

export const monsterRelations = relations(monster, ({ many, one }) => ({
	belongToMobs: many(mob, {
		relationName: 'mobTomonster'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'monsterTouser_update_data',
		fields: [monster.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'monsterTouser_create_data',
		fields: [monster.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'monsterTostatistics',
		fields: [monster.statisticsId],
		references: [statistics.id]
	}),
	image: one(image, {
		relationName: 'imageTomonster',
		fields: [monster.imageId],
		references: [image.id]
	})
}));

export const modifierRelations = relations(modifier, ({ one }) => ({
	belongToModifiersList: one(modifiers_list, {
		relationName: 'modifierTomodifiers_list',
		fields: [modifier.belongToModifiersListId],
		references: [modifiers_list.id]
	})
}));

export const modifiers_listRelations = relations(modifiers_list, ({ many }) => ({
	modifiers: many(modifier, {
		relationName: 'modifierTomodifiers_list'
	}),
	usedByMainWeapon: many(main_weapon, {
		relationName: 'main_weaponTomodifiers_list'
	}),
	usedBySubWeapon: many(sub_weapon, {
		relationName: 'modifiers_listTosub_weapon'
	}),
	usedByBodyArmor: many(body_armor, {
		relationName: 'body_armorTomodifiers_list'
	}),
	usedByAdditionalEquipment: many(additional_equipment, {
		relationName: 'additional_equipmentTomodifiers_list'
	}),
	usedBySpecialEquipment: many(special_equipment, {
		relationName: 'modifiers_listTospecial_equipment'
	}),
	usedByCrystal: many(crystal, {
		relationName: 'crystalTomodifiers_list'
	}),
	usedByFashion: many(character, {
		relationName: 'FashionRelation'
	}),
	usedByCuisine: many(character, {
		relationName: 'CuisineRelation'
	}),
	usedByModifiersList: many(character, {
		relationName: 'ModifiersListRelation'
	}),
	Consumable: many(consumable, {
		relationName: 'consumableTomodifiers_list'
	})
}));

export const crystalRelations = relations(crystal, ({ one, many }) => ({
	modifiersList: one(modifiers_list, {
		relationName: 'crystalTomodifiers_list',
		fields: [crystal.modifiersListId],
		references: [modifiers_list.id]
	}),
	usedByMainWeapon: many(crystalTomain_weapon, {
		relationName: 'crystalTocrystalTomain_weapon'
	}),
	usedByBodyArmor: many(body_armorTocrystal, {
		relationName: 'crystalTobody_armorTocrystal'
	}),
	usedByAdditionalEquipment: many(additional_equipmentTocrystal, {
		relationName: 'crystalToadditional_equipmentTocrystal'
	}),
	usedBySpecialEquipment: many(crystalTospecial_equipment, {
		relationName: 'crystalTocrystalTospecial_equipment'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'crystalTouser_update_data',
		fields: [crystal.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'crystalTouser_create_data',
		fields: [crystal.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'crystalTostatistics',
		fields: [crystal.statisticsId],
		references: [statistics.id]
	})
}));

export const main_weaponRelations = relations(main_weapon, ({ one, many }) => ({
	modifiersList: one(modifiers_list, {
		relationName: 'main_weaponTomodifiers_list',
		fields: [main_weapon.modifiersListId],
		references: [modifiers_list.id]
	}),
	crystal: many(crystalTomain_weapon, {
		relationName: 'main_weaponTocrystalTomain_weapon'
	}),
	usedBy: many(character, {
		relationName: 'characterTomain_weapon'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'main_weaponTouser_update_data',
		fields: [main_weapon.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'main_weaponTouser_create_data',
		fields: [main_weapon.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'main_weaponTostatistics',
		fields: [main_weapon.statisticsId],
		references: [statistics.id]
	}),
	image: many(image, {
		relationName: 'imageTomain_weapon'
	})
}));

export const sub_weaponRelations = relations(sub_weapon, ({ one, many }) => ({
	modifiersList: one(modifiers_list, {
		relationName: 'modifiers_listTosub_weapon',
		fields: [sub_weapon.modifiersListId],
		references: [modifiers_list.id]
	}),
	usedBy: many(character, {
		relationName: 'characterTosub_weapon'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'sub_weaponTouser_update_data',
		fields: [sub_weapon.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'sub_weaponTouser_create_data',
		fields: [sub_weapon.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'statisticsTosub_weapon',
		fields: [sub_weapon.statisticsId],
		references: [statistics.id]
	}),
	image: many(image, {
		relationName: 'imageTosub_weapon'
	})
}));

export const body_armorRelations = relations(body_armor, ({ one, many }) => ({
	modifiersList: one(modifiers_list, {
		relationName: 'body_armorTomodifiers_list',
		fields: [body_armor.modifiersListId],
		references: [modifiers_list.id]
	}),
	crystal: many(body_armorTocrystal, {
		relationName: 'body_armorTobody_armorTocrystal'
	}),
	usedBy: many(character, {
		relationName: 'body_armorTocharacter'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'body_armorTouser_update_data',
		fields: [body_armor.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'body_armorTouser_create_data',
		fields: [body_armor.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'body_armorTostatistics',
		fields: [body_armor.statisticsId],
		references: [statistics.id]
	}),
	image: many(image, {
		relationName: 'body_armorToimage'
	})
}));

export const additional_equipmentRelations = relations(additional_equipment, ({ one, many }) => ({
	modifiersList: one(modifiers_list, {
		relationName: 'additional_equipmentTomodifiers_list',
		fields: [additional_equipment.modifiersListId],
		references: [modifiers_list.id]
	}),
	crystal: many(additional_equipmentTocrystal, {
		relationName: 'additional_equipmentToadditional_equipmentTocrystal'
	}),
	usedBy: many(character, {
		relationName: 'additional_equipmentTocharacter'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'additional_equipmentTouser_update_data',
		fields: [additional_equipment.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'additional_equipmentTouser_create_data',
		fields: [additional_equipment.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'additional_equipmentTostatistics',
		fields: [additional_equipment.statisticsId],
		references: [statistics.id]
	}),
	image: many(image, {
		relationName: 'additional_equipmentToimage'
	})
}));

export const special_equipmentRelations = relations(special_equipment, ({ one, many }) => ({
	modifiersList: one(modifiers_list, {
		relationName: 'modifiers_listTospecial_equipment',
		fields: [special_equipment.modifiersListId],
		references: [modifiers_list.id]
	}),
	crystal: many(crystalTospecial_equipment, {
		relationName: 'special_equipmentTocrystalTospecial_equipment'
	}),
	usedBy: many(character, {
		relationName: 'characterTospecial_equipment'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'special_equipmentTouser_update_data',
		fields: [special_equipment.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'special_equipmentTouser_create_data',
		fields: [special_equipment.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'special_equipmentTostatistics',
		fields: [special_equipment.statisticsId],
		references: [statistics.id]
	}),
	image: many(image, {
		relationName: 'imageTospecial_equipment'
	})
}));

export const skillRelations = relations(skill, ({ many, one }) => ({
	skillEffect: many(skill_effect, {
		relationName: 'skillToskill_effect'
	}),
	usedBySkillList: many(character, {
		relationName: 'characterToskill'
	}),
	usedByComboStep: many(combo_step, {
		relationName: 'combo_stepToskill'
	}),
	usedByStep: many(step, {
		relationName: 'skillTostep'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'skillTouser_update_data',
		fields: [skill.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'skillTouser_create_data',
		fields: [skill.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'skillTostatistics',
		fields: [skill.statisticsId],
		references: [statistics.id]
	})
}));

export const skill_effectRelations = relations(skill_effect, ({ many, one }) => ({
	skillCost: many(skill_cost, {
		relationName: 'skill_costToskill_effect'
	}),
	skillYield: many(skill_yield, {
		relationName: 'skill_effectToskill_yield'
	}),
	belongToSkill: one(skill, {
		relationName: 'skillToskill_effect',
		fields: [skill_effect.belongToskillId],
		references: [skill.id]
	})
}));

export const skill_costRelations = relations(skill_cost, ({ one }) => ({
	belongToSkillEffect: one(skill_effect, {
		relationName: 'skill_costToskill_effect',
		fields: [skill_cost.skillEffectId],
		references: [skill_effect.id]
	})
}));

export const skill_yieldRelations = relations(skill_yield, ({ one }) => ({
	belongToSkillEffect: one(skill_effect, {
		relationName: 'skill_effectToskill_yield',
		fields: [skill_yield.skillEffectId],
		references: [skill_effect.id]
	})
}));

export const petRelations = relations(pet, ({ many, one }) => ({
	usedBy: many(character, {
		relationName: 'characterTopet'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'petTouser_update_data',
		fields: [pet.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'petTouser_create_data',
		fields: [pet.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'petTostatistics',
		fields: [pet.statisticsId],
		references: [statistics.id]
	})
}));

export const consumableRelations = relations(consumable, ({ one, many }) => ({
	modifiersList: one(modifiers_list, {
		relationName: 'consumableTomodifiers_list',
		fields: [consumable.modifiersListId],
		references: [modifiers_list.id]
	}),
	usedBy: many(character, {
		relationName: 'characterToconsumable'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'consumableTouser_update_data',
		fields: [consumable.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'consumableTouser_create_data',
		fields: [consumable.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'consumableTostatistics',
		fields: [consumable.statisticsId],
		references: [statistics.id]
	})
}));

export const comboRelations = relations(combo, ({ many, one }) => ({
	comboStep: many(combo_step, {
		relationName: 'comboTocombo_step'
	}),
	usedBy: many(character, {
		relationName: 'characterTocombo'
	}),
	user_create_data: one(user_create_data, {
		relationName: 'comboTouser_create_data',
		fields: [combo.userCreateUserId],
		references: [user_create_data.userId]
	})
}));

export const combo_stepRelations = relations(combo_step, ({ one }) => ({
	skill: one(skill, {
		relationName: 'combo_stepToskill',
		fields: [combo_step.skillId],
		references: [skill.id]
	}),
	belongToCombo: one(combo, {
		relationName: 'comboTocombo_step',
		fields: [combo_step.comboId],
		references: [combo.id]
	})
}));

export const characterRelations = relations(character, ({ one, many }) => ({
	mainWeapon: one(main_weapon, {
		relationName: 'characterTomain_weapon',
		fields: [character.mainWeaponId],
		references: [main_weapon.id]
	}),
	subWeapon: one(sub_weapon, {
		relationName: 'characterTosub_weapon',
		fields: [character.subWeaponId],
		references: [sub_weapon.id]
	}),
	bodyArmor: one(body_armor, {
		relationName: 'body_armorTocharacter',
		fields: [character.bodyArmorId],
		references: [body_armor.id]
	}),
	additionalEquipment: one(additional_equipment, {
		relationName: 'additional_equipmentTocharacter',
		fields: [character.additionalEquipmentId],
		references: [additional_equipment.id]
	}),
	specialEquipment: one(special_equipment, {
		relationName: 'characterTospecial_equipment',
		fields: [character.specialEquipmentId],
		references: [special_equipment.id]
	}),
	fashion: one(modifiers_list, {
		relationName: 'FashionRelation',
		fields: [character.fashionModifiersListId],
		references: [modifiers_list.id]
	}),
	cuisine: one(modifiers_list, {
		relationName: 'CuisineRelation',
		fields: [character.CuisineModifiersListId],
		references: [modifiers_list.id]
	}),
	pet: one(pet, {
		relationName: 'characterTopet',
		fields: [character.petId],
		references: [pet.id]
	}),
	modifiersList: one(modifiers_list, {
		relationName: 'ModifiersListRelation',
		fields: [character.modifiersListId],
		references: [modifiers_list.id]
	}),
	skillList: many(skill, {
		relationName: 'characterToskill'
	}),
	consumableList: many(consumable, {
		relationName: 'characterToconsumable'
	}),
	combos: many(combo, {
		relationName: 'characterTocombo'
	}),
	belongToMembers: many(memeber, {
		relationName: 'characterTomemeber'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'characterTouser_update_data',
		fields: [character.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'characterTouser_create_data',
		fields: [character.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'characterTostatistics',
		fields: [character.statisticsId],
		references: [statistics.id]
	}),
	image: one(image, {
		relationName: 'characterToimage',
		fields: [character.imageId],
		references: [image.id]
	})
}));

export const memeberRelations = relations(memeber, ({ one, many }) => ({
	character: one(character, {
		relationName: 'characterTomemeber',
		fields: [memeber.characterId],
		references: [character.id]
	}),
	belongToAnalyzers: many(analyzer, {
		relationName: 'analyzerTomemeber'
	})
}));

export const mobRelations = relations(mob, ({ one, many }) => ({
	monster: one(monster, {
		relationName: 'mobTomonster',
		fields: [mob.monsterId],
		references: [monster.id]
	}),
	belongToAnalyzers: many(analyzer, {
		relationName: 'analyzerTomob'
	})
}));

export const analyzerRelations = relations(analyzer, ({ many, one }) => ({
	mobs: many(mob, {
		relationName: 'analyzerTomob'
	}),
	team: many(memeber, {
		relationName: 'analyzerTomemeber'
	}),
	updatedBy: one(user_update_data, {
		relationName: 'analyzerTouser_update_data',
		fields: [analyzer.updatedByUserId],
		references: [user_update_data.userId]
	}),
	createdBy: one(user_create_data, {
		relationName: 'analyzerTouser_create_data',
		fields: [analyzer.createdByUserId],
		references: [user_create_data.userId]
	}),
	statistics: one(statistics, {
		relationName: 'analyzerTostatistics',
		fields: [analyzer.statisticsId],
		references: [statistics.id]
	})
}));

export const processRelations = relations(process, ({ many }) => ({
	steps: many(step, {
		relationName: 'processTostep'
	})
}));

export const stepRelations = relations(step, ({ one }) => ({
	skill: one(skill, {
		relationName: 'skillTostep',
		fields: [step.skillId],
		references: [skill.id]
	}),
	belongToProcess: one(process, {
		relationName: 'processTostep',
		fields: [step.processId],
		references: [process.id]
	})
}));

export const statisticsRelations = relations(statistics, ({ many }) => ({
	usageTimestamps: many(usage_timestamp, {
		relationName: 'statisticsTousage_timestamp'
	}),
	viewTimestamps: many(view_timestamp, {
		relationName: 'statisticsToview_timestamp'
	}),
	rates: many(rate, {
		relationName: 'rateTostatistics'
	}),
	belongToMonster: many(monster, {
		relationName: 'monsterTostatistics'
	}),
	belongToCrystal: many(crystal, {
		relationName: 'crystalTostatistics'
	}),
	belongToMainWeapon: many(main_weapon, {
		relationName: 'main_weaponTostatistics'
	}),
	belongToSubWeapon: many(sub_weapon, {
		relationName: 'statisticsTosub_weapon'
	}),
	belongToBodyArmor: many(body_armor, {
		relationName: 'body_armorTostatistics'
	}),
	belongToAdditionalEquipment: many(additional_equipment, {
		relationName: 'additional_equipmentTostatistics'
	}),
	belongToSpecialEquipment: many(special_equipment, {
		relationName: 'special_equipmentTostatistics'
	}),
	belongToSkill: many(skill, {
		relationName: 'skillTostatistics'
	}),
	belongToPet: many(pet, {
		relationName: 'petTostatistics'
	}),
	belongToConsumable: many(consumable, {
		relationName: 'consumableTostatistics'
	}),
	Character: many(character, {
		relationName: 'characterTostatistics'
	}),
	Analyzer: many(analyzer, {
		relationName: 'analyzerTostatistics'
	})
}));

export const imageRelations = relations(image, ({ many, one }) => ({
	usedByMonster: many(monster, {
		relationName: 'imageTomonster'
	}),
	belongToMainWeapon: one(main_weapon, {
		relationName: 'imageTomain_weapon',
		fields: [image.main_weaponId],
		references: [main_weapon.id]
	}),
	belongToSubWeapon: one(sub_weapon, {
		relationName: 'imageTosub_weapon',
		fields: [image.sub_weaponId],
		references: [sub_weapon.id]
	}),
	belongToBodyArmor: one(body_armor, {
		relationName: 'body_armorToimage',
		fields: [image.body_armorId],
		references: [body_armor.id]
	}),
	belongToAdditionalEquipment: one(additional_equipment, {
		relationName: 'additional_equipmentToimage',
		fields: [image.additional_equipmentId],
		references: [additional_equipment.id]
	}),
	belongToSpecialEquipment: one(special_equipment, {
		relationName: 'imageTospecial_equipment',
		fields: [image.special_equipmentId],
		references: [special_equipment.id]
	}),
	usedByCharacter: many(character, {
		relationName: 'characterToimage'
	})
}));

export const rateRelations = relations(rate, ({ one }) => ({
	belongToUser: one(user, {
		relationName: 'rateTouser',
		fields: [rate.userId],
		references: [user.id]
	}),
	belongToStatistics: one(statistics, {
		relationName: 'rateTostatistics',
		fields: [rate.statisticsId],
		references: [statistics.id]
	})
}));

export const usage_timestampRelations = relations(usage_timestamp, ({ one }) => ({
	usedByStatistics: one(statistics, {
		relationName: 'statisticsTousage_timestamp',
		fields: [usage_timestamp.statisticsId],
		references: [statistics.id]
	})
}));

export const view_timestampRelations = relations(view_timestamp, ({ one }) => ({
	usedByStatistics: one(statistics, {
		relationName: 'statisticsToview_timestamp',
		fields: [view_timestamp.statisticsId],
		references: [statistics.id]
	})
}));

export const crystalTomain_weaponRelations = relations(crystalTomain_weapon, ({ one }) => ({
	main_weapon: one(main_weapon, {
		relationName: 'main_weaponTocrystalTomain_weapon',
		fields: [crystalTomain_weapon.main_weaponId],
		references: [main_weapon.id]
	}),
	crystal: one(crystal, {
		relationName: 'crystalTocrystalTomain_weapon',
		fields: [crystalTomain_weapon.crystalId],
		references: [crystal.id]
	})
}));

export const body_armorTocrystalRelations = relations(body_armorTocrystal, ({ one }) => ({
	crystal: one(crystal, {
		relationName: 'crystalTobody_armorTocrystal',
		fields: [body_armorTocrystal.crystalId],
		references: [crystal.id]
	}),
	body_armor: one(body_armor, {
		relationName: 'body_armorTobody_armorTocrystal',
		fields: [body_armorTocrystal.body_armorId],
		references: [body_armor.id]
	})
}));

export const additional_equipmentTocrystalRelations = relations(additional_equipmentTocrystal, ({ one }) => ({
	crystal: one(crystal, {
		relationName: 'crystalToadditional_equipmentTocrystal',
		fields: [additional_equipmentTocrystal.crystalId],
		references: [crystal.id]
	}),
	additional_equipment: one(additional_equipment, {
		relationName: 'additional_equipmentToadditional_equipmentTocrystal',
		fields: [additional_equipmentTocrystal.additional_equipmentId],
		references: [additional_equipment.id]
	})
}));

export const crystalTospecial_equipmentRelations = relations(crystalTospecial_equipment, ({ one }) => ({
	special_equipment: one(special_equipment, {
		relationName: 'special_equipmentTocrystalTospecial_equipment',
		fields: [crystalTospecial_equipment.special_equipmentId],
		references: [special_equipment.id]
	}),
	crystal: one(crystal, {
		relationName: 'crystalTocrystalTospecial_equipment',
		fields: [crystalTospecial_equipment.crystalId],
		references: [crystal.id]
	})
}));